@import './fonts.css';


:root {
  color-scheme: light dark;
  --primary-color: #40ba8d;
  --main-color: #2d3d35;
  --main-color2: #332d3d7f;
  --main-color3: #2d3d3535;
  --main-color4: #2d3d3520;
  --border-color: #2d3d35;
}



body {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  padding: 0;
  margin: 0;  
  direction: rtl;
  overflow-x: hidden;
  background: url('/images/back.jpg') center center no-repeat;
  background-attachment: fixed;
}

a {
  text-decoration: none;
}
